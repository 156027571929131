import { Helmet } from "react-helmet";
import React from "react";
import ImageFeature1 from '../../../assets/static/foods_img_1.jpg';
import Food from "../../Home/Components/Foods";

const FoodPage = props => {
    return (
        <>
        <div className="category-search-panel">
                <Food category />
            </div>

            <div className="main-100 press preview-page">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Food | Followone</title>
                    <meta name="description" content="Food is currently under development." />
                    <link rel="canonical" href="https://followone.com/food" />
                </Helmet>

                <div className="main-100-header">
                    <h1>Followone Foods</h1>
                    <p>Under Development</p>
                </div>

                <div className="static-page-feature">
                    <img src={ImageFeature1} alt="Followone Foods" />
                    <p style={{ textAlign: 'left' }}>
                    Get ready for a delicious new way to explore food. Followone Foods is currently in development and will soon bring a wide range of features designed to connect you with local flavors and everyday essentials. From browsing restaurant menus and reserving tables to ordering delivery and discovering nearby grocery listings, our future platform will make food exploration and access easier than ever. Whether you're craving your favorite dish or stocking up on fresh ingredients, Followone Foods will be your go-to destination for all things food.</p>
                </div>
            </div>
        </>
    )
}

export default FoodPage;