import React, { Component } from 'react';
import MobileMenuModal from './MobileMenuModal';

class MobileMenu extends Component {
    state = {
        show: false
    }

    showMenu = () => {
        this.setState({ show: true });
    }

    hideMenu = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <>
                <div className="mobile-menu-icon">
                    {!(this.state.show) ?
                        (<button onClick={this.showMenu}><i className="far fa-bars" /></button>) :
                        (<button onClick={this.hideMenu}><i className="fal fa-times" /></button>)} {/* fal fa-times when modal is open */}
                </div>

                <MobileMenuModal show={this.state.show} onClick={this.hideMenu} />
            </>
        );
    }
}

export default MobileMenu; 