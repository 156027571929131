import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import actionType from "../../../../redux-store/action-type";
import { getTranslatedPhrase } from "../../../util/lang";
import Axios from "axios";
import { hostNameAndPort } from "../../../util/vars";
import Backdrop from "../../../uielements/Backdrop/Backdrop";
import Box from "../../../util/box";

class MobileMenuModal extends React.Component {
  state = {
    affiliateId: "",
    accountType: "unknown",
    userIsAuthenticated: false,
    userLanguage: "en_EN",
    pageRefresh: "",
  };

  onChangeLanguage = (e) => {
    let v = e.target.value;
    if (v !== "ro_RO" && v !== "en_EN") {
      return;
      /**
            ar_EG  bs  de  en  fa  fil  hi_IN  hu  nb_NO  pl  ro  sq  uk  zh_CN
            bg     da  el  es  fi  fr   hr_HR  it  nl     pt  ru  sr  vi  zh_TW
            */
    }
    this.setState({ userLanguage: v });
    document.cookie = `userLanguage=${v};max-age=900000;http-only=true`;
    this.props.onUpdateUserLanguage(v);
  };

  getAccountType = async () => {
    let url = `${hostNameAndPort}/api/user/affiliate-info`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState(data.user);
      } else
        this.setState({
          affiliateId: "",
          accountType: "unknown",
          userIsAuthenticated: false,
        });
    } catch (err) {
      // Network error
    }
  };

  componentDidMount() {
    let cookie = document.cookie;
    if (
      cookie === undefined ||
      !/\s*userLanguage\s*=\s*.*(;\s*|$)/.test(cookie)
    ) {
      document.cookie = `userLanguage=${this.state.userLanguage};max-age=900000;http-only=true`;
    } else {
      let a = document.cookie;
      let v = a.substring(a.indexOf("userLanguage=") + 13);
      if (v.indexOf(";") >= 0) v = v.substring(0, v.indexOf(";")).trim();
      this.setState({ userLanguage: v });
      this.props.onUpdateUserLanguage(v);
    }
    this.getAccountType();
  }

  componentDidUpdate() {
    let p = this.props.userIsAuthenticated;
    let q = this.props.pageRefresh;
    if (this.state.userIsAuthenticated !== p || this.state.pageRefresh !== q) {
      this.setState({ userIsAuthenticated: p, pageRefresh: q });
      this.getAccountType();
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Box>
        <Backdrop show={this.props.show} clicked={this.props.onClick} />
        <div className="mobile-menu-modal">
          <div className="MobileMenuBox">
            <h4>{getTranslatedPhrase(this.props.userLanguage, "MENU")}</h4>

            <ul
              className="mobileNavSocial mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <Link to="/">
                  <i className="fal fa-home" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Home")}
                </Link>
              </li>
            </ul>

            <ul
              className="mobileNavSpecial mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>Specials & Deals</span>
              </li>
              {!this.state.affiliateId ||
              this.state.accountType !== "personal" ? (
                <li>
                  <Link to="/specials">
                    <i className="fal fa-tags sale-gradient" />{" "}
                    {getTranslatedPhrase(this.props.userLanguage, "Specials")}
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/notifications">
                    <i className="fal fa-hand-holding-usd sale-gradient" />{" "}
                    {getTranslatedPhrase(this.props.userLanguage, "Cashin")}
                    <span className="reg-td">&reg;</span>
                  </Link>
                </li>
              )}
            </ul>

            {/* <ul className="mobileNavSocial mobileNavContainer" onClick={this.props.onClick}>
                        <li><span>Social</span></li>
                        <li><Link to="/blogs"><i className="fal fa-blog blog-gradient"></i> {getTranslatedPhrase(this.props.userLanguage,'Blogs')}</Link></li>
                        <li><Link to="/photos"><i className="fal fa-camera-retro photos-gradient"></i> {getTranslatedPhrase(this.props.userLanguage,'Photos')}</Link></li>
                        <li><Link to="/videos"><i className="fal fa-video videos-gradient"></i> {getTranslatedPhrase(this.props.userLanguage,'Videos')}</Link></li>
                        <li><Link to="/events"><i className="fal fa-calendar-alt events-gradient"></i> {getTranslatedPhrase(this.props.userLanguage,'Events')}</Link></li>
                        <li><Link to="/groups"><i className="fal fa-users groups-gradient"></i> {getTranslatedPhrase(this.props.userLanguage,'Groups')}</Link></li>
                    </ul> */}

            <ul
              className="mobileNavStore mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>Buy / Sell</span>
              </li>
              <li>
                <Link
                  onClick={() => this.props.pageShouldRefresh("/autos")}
                  to="/autos"
                >
                  <i className="fal fa-car auto-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Auto")}
                </Link>
              </li>
              <li>
                <Link to="/realestate">
                  <i className="fal fa-building real-estate-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Real Estate")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.props.pageShouldRefresh("/store")}
                  to="/store"
                >
                  <i className="fal fa-shopping-bag store-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Store")}
                </Link>
              </li>
              <li>
                <Link to="/food">
                  <i className="fal fa-utensils food-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Food")}
                </Link>
              </li>
              <li>
                <Link to="/travel">
                  <i className="fal fa-bed travel-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Travel")}
                </Link>
              </li>
              <li>
                <Link to="/healthcare">
                  <i className="fal fa-heart healthcare-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Healthcare")}
                </Link>
              </li>
            </ul>

            <ul
              className="mobileNavServices mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Jobs") +
                    " & " +
                    getTranslatedPhrase(this.props.userLanguage, "Services")}
                </span>
              </li>
              <li>
                <Link to="/jobs">
                  <i className="fal fa-briefcase jobs-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Jobs")}
                </Link>
              </li>
              <li>
                <Link to="/services">
                  <i className="fal fa-wrench services-gradient" />{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Services")}
                </Link>
              </li>
            </ul>

            <ul
              className="mobileNavAbout mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>
                  {getTranslatedPhrase(this.props.userLanguage, "About")}
                </span>
              </li>
              <li>
                <Link to="/about">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "About Us")}
                </Link>
              </li>
              {/* <li><Link to="/meet-the-team"> {getTranslatedPhrase(this.props.userLanguage, 'The Team')}</Link></li> */}
              <li>
                <Link to="/directory">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Directory")}
                </Link>
              </li>
              <li>
                <Link to="/help">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "FAQ")}
                </Link>
              </li>
              <li>
                <Link to="/careers">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Careers")}
                </Link>
              </li>
              <li>
                <Link to="/press">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Press")}
                </Link>
              </li>
            </ul>

            <ul
              className="mobileNavPartners mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Partners")}
                </span>
              </li>
              <li>
                <Link to="/advertise">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Advertise")}
                </Link>
              </li>
              <li>
                <Link to="/affiliates">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Affiliates")}
                </Link>
              </li>
              <li>
                <Link to="/investors">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Investors")}
                </Link>
              </li>
              <li>
                <Link to="/start-ups">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Start-Ups")}
                </Link>
              </li>
            </ul>

            <ul
              className="mobileNavLegal mobileNavContainer"
              onClick={this.props.onClick}
            >
              <li>
                <span>
                  {getTranslatedPhrase(this.props.userLanguage, "Legal")}
                </span>
              </li>
              <li>
                <Link to="/privacy">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Privacy")}
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service">
                  {" "}
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Terms of Service"
                  )}
                </Link>
              </li>
              <li>
                <Link to="/copyright">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Copyright")}
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  {" "}
                  {getTranslatedPhrase(this.props.userLanguage, "Contact")}
                </Link>
              </li>
            </ul>

            <ul className="mobileAppLanguage mobileNavContainer">
              <li>
                <span>
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Select Language"
                  )}
                </span>
              </li>
              <li>
                <div className="form-input-select mgl-sm dsp-in-blk">
                  <select
                    className="form-input-full"
                    value={this.state.userLanguage}
                    onChange={this.onChangeLanguage}
                  >
                    <option value="en_EN">
                      {getTranslatedPhrase(this.props.userLanguage, "English")}
                    </option>
                    <option value="ro_RO">
                      {getTranslatedPhrase(this.props.userLanguage, "Romanian")}
                    </option>
                    <option value="ar_EG">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Arabic (Coming Soon)"
                      )}
                    </option>
                    <option value="bg">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Bulgarian (Coming Soon)"
                      )}
                    </option>
                    <option value="bs">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Bosnian (Coming Soon)"
                      )}
                    </option>
                    <option value="da">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Danish (Coming Soon)"
                      )}
                    </option>
                    <option value="de">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "German (Coming Soon)"
                      )}
                    </option>
                    <option value="el">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Greek (Coming Soon)"
                      )}
                    </option>
                    <option value="es">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Spanish (Coming Soon)"
                      )}
                    </option>
                    <option value="fa">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Farsi (Coming Soon)"
                      )}
                    </option>
                    <option value="fi">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Finnish (Coming Soon)"
                      )}
                    </option>
                    <option value="fr">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "French (Coming Soon)"
                      )}
                    </option>
                    <option value="hi_IN">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Hindi (Coming Soon)"
                      )}
                    </option>
                    <option value="hr_HR">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Croatian (Coming Soon)"
                      )}
                    </option>
                    <option value="hu">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Hungarian (Coming Soon)"
                      )}
                    </option>
                    <option value="it">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Italian (Coming Soon)"
                      )}
                    </option>
                    <option value="nb_NO">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Norwegian (Coming Soon)"
                      )}
                    </option>
                    <option value="nl">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Dutch (Coming Soon)"
                      )}
                    </option>
                    <option value="pl">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Polish (Coming Soon)"
                      )}
                    </option>
                    <option value="pt">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Portuguese (Coming Soon)"
                      )}
                    </option>
                    <option value="ru">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Russian (Coming Soon)"
                      )}
                    </option>
                    <option value="sq">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Albanian (Coming Soon)"
                      )}
                    </option>
                    <option value="sr">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Serbian (Coming Soon)"
                      )}
                    </option>
                    <option value="uk">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Ukrainian (Coming Soon)"
                      )}
                    </option>
                    <option value="vi">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Vietnamese (Coming Soon)"
                      )}
                    </option>
                    <option value="zh_CN">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Simplified Chinese (Coming Soon)"
                      )}
                    </option>
                  </select>
                </div>
              </li>
            </ul>

            <button onClick={this.props.onClick}>
              <i className="fal fa-times" />
            </button>
          </div>
        </div>
      </Box>
    );
  }
}

const mapStateToMobileMenuProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
  };
};

const mapDispatchToMobileMenuModalProps = (dispatch) => {
  return {
    pageShouldRefresh: (p) => {
      dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
    },
    onUpdateUserLanguage: (v) => {
      dispatch({ type: actionType.UPDATE_USER_LANGUAGE, userLanguage: v });
    },
  };
};

export default connect(
  mapStateToMobileMenuProps,
  mapDispatchToMobileMenuModalProps
)(MobileMenuModal);
