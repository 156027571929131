import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function StoreSideBar(props) {
  // return (<SideBar {...props} 
  //   section="store"
  //   searchTitle="Search Products"
  //   createNewTitle="New Store Listing"
  //   filterList={[
  //     { name:'keywords', value: 'Enter Keywords' }, 
  //   ]}
  // />);
  return null;
}

export default StoreSideBar;