import React, { useState, useEffect } from "react";
import BackgroundImage from "../../../assets/home/home-search-store.jpg";
import { withRouter, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import actionType from "../../../redux-store/action-type";
import AdvStore from "../../../shared/components/Modals/Search/AdvStore";
import { getTranslatedPhrase } from '../../../shared/util/lang';
import Autosuggest from 'react-autosuggest';
import Axios from 'axios';
import { hostNameAndPort } from "../../../shared/util/vars";

const StoreSearch = props => {
    const [title, setTitle] = useState("");
    const [withCoupons, setWithCoupons] = useState(false);
    const [priceAscending, setPriceAscending] = useState(0);
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [viewsDescending, setViewsDescending] = useState(false);
    const [rating, setRating] = useState(0);
    const [productCondition, setProductCondition] = useState("New");
    const [localOnly, setLocalOnly] = useState(false);
    const [cityMissing, setCityMissing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [address, setAddress] = useState('');
    const [stateVar, setStateVar] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');

    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);
    const searchQuery = useSelector(state => state.searchQuery);
    const [country, setCountry] = useState(JSON.parse(searchQuery || '{}').countryUpdated);
    const windowLocationSearch = window.location.search;

    function checkLogin() {
        if (!loggedInUser) props.onClickLoginButtonHandler("/store/new-product");
        else props.history.push("/store/new-product");
    }

    let currentCountry = JSON.parse(searchQuery || '{}').countryUpdated;
    useEffect(() => {
        if (currentCountry !== country) {
            const ts = {
                category,
                subcategory,
                productCondition,
                title,
                address,
                country,
                stateVar,
                city,
                zipCode,
                priceAscending,
                priceMin,
                priceMax,
                viewsDescending,
                withCoupons,
                rating
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            if (!!props.category) props.history.push('/store');
        }
    }, [currentCountry]);

    useEffect(() => {
        if (!windowLocationSearch) {
            const ts = {
                category,
                subcategory,
                productCondition,
                title,
                address,
                country,
                stateVar,
                city,
                zipCode,
                priceAscending,
                priceMin,
                priceMax,
                viewsDescending,
                withCoupons,
                rating
            };
            for (let i of Object.keys(ts)) onChangeItem('', i);
            setCountry(currentCountry);
            return;
        }
        const urlParams = new URLSearchParams(windowLocationSearch);

        let cat = urlParams.get('category') || '';
        cat = cat.replace(/%26/g, '&');
        if (cat === 'all') cat = '';
        let subcat = urlParams.get('subcategory') || '';
        subcat = subcat.replace(/%26/g, '&');
        if (subcat === 'all') subcat = '';
        if (category !== cat) setCategory(cat);
        if (subcategory !== subcat) setSubcategory(subcat);

        const ts = {
            // category,
            // subcategory,
            productCondition,
            title,
            address,
            country,
            stateVar,
            city,
            zipCode,
            priceAscending,
            priceMin,
            priceMax,
            viewsDescending,
            withCoupons,
            rating
        };
        let changeState = false;

        const ns = {
            // category: urlParams.get('category') || '',
            // subcategory: urlParams.get('subcategory') || '',
            productCondition: urlParams.get('condition') || 'New',
            title: urlParams.get('keywords') || '',
            address: urlParams.get('address') || '',
            country: urlParams.get('country') || '',
            stateVar: urlParams.get('state') || '',
            city: urlParams.get('city') || '',
            zipCode: urlParams.get('zipCode') || '',
            priceAscending: Number(urlParams.get('priceAscending')) || 0,
            priceMin: Number(urlParams.get('priceMin')) || '',
            priceMax: Number(urlParams.get('priceMax')) || '',
            viewsDescending: urlParams.get('viewsDescending') || false,
            withCoupons: urlParams.get('withCoupons') || false,
            rating: Number(urlParams.get('rating')) || 0
        };
        if (!ns.viewsDescending || ns.viewsDescending === 'false') ns.viewsDescending = false; else ns.viewsDescending = true;
        if (!ns.withCoupons || ns.withCoupons === 'false') ns.withCoupons = false; else ns.withCoupons = true;

        let keywordsParts = ns.title.split('-');
        if (keywordsParts.length > 1) {
            keywordsParts.shift();
            keywordsParts.pop();
        }
        ns.title = keywordsParts.join('-');

        let noFilters = true;
        for (let i in ns)
            if (!!ns[i]) {
                noFilters = false;
                break;
            }

        if (noFilters) return;


        if (ns.address !== ts.address || ns.withCoupons !== ts.withCoupons || ns.title !== ts.title ||
            ns.productCondition !== ts.productCondition || ns.country !== ts.country || ns.city !== ts.city || ns.stateVar !== ts.stateVar || ns.zipCode !== ts.zipCode)
            changeState = true;


        if (changeState) {
            for (let i of Object.keys(ns)) onChangeItem(ns[i], i);
            if (!!ns.city || !!ns.stateVar) onChangeItem(ns.city + ', ' + ns.stateVar, 'address');
        }
    }, [windowLocationSearch]);

    function onChangeItem(_value, _prop, regularChange) {
        let changeNow = {
            category: setCategory,
            subcategory: setSubcategory,
            productCondition: setProductCondition,
            localOnly: setLocalOnly,
            title: setTitle,
            withCoupons: setWithCoupons,
            country: setCountry,
            stateVar: setStateVar,
            city: setCity,
            zipCode: setZipCode,
            // title: setTitle,
            priceAscending: setPriceAscending,
            priceMin: setPriceMin,
            priceMax: setPriceMax,
            viewsDescending: setViewsDescending,
            rating: setRating
        };
        if (_prop === "productCondition" && productCondition === _value && !!regularChange) _value = 'New';
        if (_prop === "localOnly" && (!_value || _value === 'false')) onChangeAddress(null, { newValue: '' });
        if (_prop === "localOnly" && localOnly === _value && !!regularChange) _value = false;
        if (_prop === "withCoupons" && withCoupons === _value && !!regularChange) _value = false;
        if (_prop === "title" && _value.length > 200) return;
        if (_prop === 'address') {
            if (!!_value) setLocalOnly(true); else setLocalOnly(false);
            onChangeAddress(null, { newValue: _value });
        }
        else changeNow[_prop](_value);
    }

    function onChangeAddress(event, { newValue }) {
        if (/\\/g.test(newValue)) return;
        if (/^ +/.test(newValue)) newValue = newValue.replace(/^ +/, '');
        if (address.length < 2 && newValue.length >= 1 && !cityList.length && !/^[0-9]+/.test(newValue)) getCityList(newValue.substring(0, 2));
        if (newValue.length < 2) {
            if (cityList.length)
                setCityList([]);
            setCityMissing(false);
        }
        if (/^, ?/.test(newValue)) {
            setCityMissing(true);
            newValue = newValue.replace(/^, ?/, '');
        }
        setAddress(newValue);
    }

    async function getCityList(value) {
        try {

            let localCountry;

            if (!country) {
                if (document.cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
                    let data = document.cookie.match(/fo_userCountry=([^;]*)(;|$)/);
                    localCountry = data[1] || 'USA';
                }
            } else {
                localCountry = country;
            }

            let result = await Axios(hostNameAndPort + '/api/cities/options-for/' + localCountry + '/' + value);
            let { data } = result;
            if (!data.error) {
                setCityList(data.items);
            } else setCityList([]);
        } catch (error) {
            setCityList([]);
        }
    }

    function getSuggestions(value = '') {
        if (/\\/g.test(value)) return cityList;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        let a = cityList;
        let r;
        if (value.length && value[value.length - 1] === ' ') {
            let v = value.substring(0, value.length - 1);
            r = new RegExp('^' + v + ',?', 'i');
        } else r = new RegExp('^' + value, 'i');
        a = a.filter(v => r.test(v));

        return inputLength === 0 ? [] : a;
    }

    function getSuggestionValue(suggestion) {
        return suggestion;
    }

    function renderSuggestion(suggestion) {
        return (
            <div>
                {suggestion}
            </div>
        );
    }

    function onSuggestionsFetchRequested({ value }) {
        setSuggestions(getSuggestions(value));
    }

    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function onSubmitStoreForm(e, s) {
        e.preventDefault();
        let f;
        if (!title) f = "filters-" + Date.now();
        else f = "filters-" + title + "-" + Date.now();
        let priceRange = (priceMin && priceMin !== '0') || (priceMax && priceMax !== '0');

        let a = address;
        if (cityMissing) {
            a = ', ' + a;
        }
        a = a.split(',');
        let city = "";
        let zipCode = "";
        let state = "";
        if (a.length > 1) {
            city = a[0].trim();
            state = a[1].trim();
        } else {
            a = a[0].trim();
            if (/^[-a-z' ]+$/.test(a.toLowerCase())) city = a;
            // else if (/^[-a-z0-9]+$/.test(a.toLowerCase())) zipCode = a;
        }
        let s_q = JSON.parse(searchQuery || '{}');
        if (!state && country === s_q.country) {
            if (!localOnly) {
                state = '';
            } else {
                state = s_q.region;
            }
        }

        let pCond;

        if (productCondition === '') {
            pCond = 'New'
        } else {
            pCond = productCondition;
        }

        if (!s) props.history.push(`/store/search/${f}?priceAscending=${priceAscending}&priceRange=${priceRange}&priceMin=${priceMin}&priceMax=${priceMax}&viewsDescending=${viewsDescending}&address=&
withCoupons=${withCoupons}&rating=0&local=${localOnly}&condition=${pCond}&city=${city}&zipCode=${zipCode}&state=${state}&country=${country}&keywords=${f}`);
        else {
            // s = s.replace(/filters-[0-9]*/g, f);
            props.history.push(s);
        }
    }

    const advancedSearchHandler = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };


    //    const maxLengthCheck = (object) => {
    //         if (object.target.value.length > object.target.maxLength) {
    //          object.target.value = object.target.value.slice(0, object.target.maxLength)
    //           }
    //         }

    const inputProps = {
        placeholder: getTranslatedPhrase(userLanguage, 'Type a city, state.'),
        value: address,
        onChange: onChangeAddress,
        // onInput: maxLengthCheck,
        // maxLength = '10' 
    };

    return (
        <>
            {modalOpen && <AdvStore closeModal={closeModal} {...props} onSubmitHandler={onSubmitStoreForm} />}
            <div className="home-search-box" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="hsb-background" />
                <div className="hsb-container">
                    <h2>{getTranslatedPhrase(userLanguage, 'Store')}</h2>
                    <form onSubmit={onSubmitStoreForm}>
                        <div className="hsb-filter">
                            {/* <input type="radio" name="storeoption" id="storenew" checked={productCondition === "New"} onChange={e => null} /> <label htmlFor="storenew" onClick={e => onChangeItem("New", "productCondition", true)}>{getTranslatedPhrase(userLanguage, 'New')}</label> */}
                            {/* <input type="radio" name="storeoption" id="storeused" checked={productCondition === "Used"} onChange={e => null} /> <label htmlFor="storeused" onClick={e => onChangeItem("Used", "productCondition", true)}>{getTranslatedPhrase(userLanguage, 'Used')}</label> */}
                            <input type="radio" name="storeDeals" id="storedeals" checked={withCoupons === true} onChange={e => null} /> <label htmlFor="storedeals" onClick={e => onChangeItem(!withCoupons, "withCoupons", true)}>{getTranslatedPhrase(userLanguage, 'Deals')}</label>

                            {/* If this is checked then show the input below and search in his area, same as the rest. */}
                            {/* if this is not checked, still search in his area but show products sold online as well. */}
                            <input type="radio" name="storalocation" id="storalocation" checked={localOnly} onChange={() => null} /> <label htmlFor="storalocation" onClick={e => onChangeItem(!localOnly, "localOnly", true)}>{getTranslatedPhrase(userLanguage, 'Local')}</label>
                        </div>

                        <div className="hsb-input hsb-input-store">
                            <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Find things you love.")} value={title} onChange={e => onChangeItem(e.target.value, "title")} maxLength="50" />
                        </div>

                        <div className="date-flex">

                            <div className="hsb-input hsb-input-store-condition">
                                    <select className="form-input-full" name="transaction" id="transaction" value={productCondition} onChange={e => onChangeItem(e.target.value, "productCondition", true)}>
                                        <option value="New">{getTranslatedPhrase(userLanguage, 'New')}</option>
                                        <option value="Used">{getTranslatedPhrase(userLanguage, 'Used')}</option>
                                    </select>
                                </div>

                            {localOnly && (
                                <div className="hsb-input hsb-input-location hsb-input-store-location">
                                    {/* <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Location: City, State or ZIP Code.")}  /> */}
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}

                                    />
                                </div>
                            )} 

                        </div>

                        <div className="hsb-buttons">
                            <div>
                                <button className="button button-white" type="submit" onSubmit={onSubmitStoreForm}>{getTranslatedPhrase(userLanguage, 'Search')}</button>
                                {props.category && <button className="button button-white" type="button" onClick={advancedSearchHandler}>{getTranslatedPhrase(userLanguage, 'Advanced Search')}</button>}
                            </div>
                            <div>
                                <Link to="/store">{props.category ? getTranslatedPhrase(userLanguage, "Store Home") : getTranslatedPhrase(userLanguage, "Home")}</Link> /
                                <button style={{ fontSize: "1.4rem", color: "#fff", marginLeft: "1rem" }} onClick={checkLogin}>{getTranslatedPhrase(userLanguage, 'Sell Products')}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="coming-soon">
                    {getTranslatedPhrase(userLanguage, 'Under Development')} 
                    {!props.category && <Link to="/store" className="button">{getTranslatedPhrase(userLanguage, 'Preview')}</Link>}
                </div>
            </div>
        </>
    );
};

const mapDispatchToStoreSearchProps = dispatch => {
    return {
        onClickLoginButtonHandler: (returnUrl) => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl })
    };
};

export default connect(null, mapDispatchToStoreSearchProps)(withRouter(StoreSearch));