import React from 'react';

const RealEstateSeller = props => {

    return (
        <div className="mls-sellers">
            <div className="mls-seller mls-seller-1">
                <div className="mls-seller-header">
                    {(props.isMlsBiz || props.isMlsCom) &&
                        <div className="mls-seller-image" style={{ backgroundImage: `url(https://www.commercialmls.com/Media/Agent/${props.seller1ID}.jpg)` }} />
                    }
                    <div>
                        <p><strong>Listing Agent</strong></p>
                        <div className="mls-seller-name">{props.seller1name}</div>
                        {props.isMlsNw && props.officeName && <p>{props.officeName}</p>}
                    </div>
                </div>
                <div className="mls-seller-contact">
                    {props.seller1phone &&
                        <div className="mls-seller-phone">
                            <i className="fal fa-phone-alt" /> {props.seller1phone}
                            {props.seller1phoneExt ? ' Ext: ' + props.seller1phoneExt : ''}
                        </div>}
                    {props.seller1email && <div className="mls-seller-email"><i className="fal fa-envelope" /> {props.seller1email}</div>}
                    {props.officeAddress &&
                        <div className="mls-address">
                            <div className="mls-address-box">
                                <i className="fal fa-map-marker-alt" />
                                <span style={{ whiteSpace: "pre-line" }}>
                                    <strong>Agency Address</strong><br />
                                    {(!props.officeName || props.officeName === 'null') ? '' : props.officeName}<br />
                                    {props.officeAddress}
                                </span>
                            </div>
                        </div>
                    }
                </div>

                <div className="touch-contact">

                    {/* {(!props.loggedInUser || (props.loggedInUser && props.loggedInUser.userId !== props.authorId)) &&
                        <button data-tip="Chat" onClick={props.onClickContact}><i className="fal fa-comment-lines" /></button>
                    } */}

                    {!props.seller1phone ? null :
                        (<a data-tip="Call" href={`tel:${props.seller1phone}`}><i className="fal fa-phone-alt" /></a>)}
                    {!props.seller1email ? null : (
                        <a data-tip="eMail" href={`mailto:${props.seller1email}`}><i className="fal fa-envelope" /></a>)}
                    {!props.officeAddress ? null : (
                        <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${props.officeAddress}/`}><i className="fal fa-map-marker-alt" /></a>)}
                </div>
            </div>

            {props.seller2phone &&
                <div className="mls-seller mls-seller-2">
                    <div className="mls-seller-header">
                        <div className="mls-seller-image" style={{ backgroundImage: `url(https://www.commercialmls.com/Media/Agent/${props.seller2ID}.jpg)` }} />
                        <div>
                            <p><strong>2nd Agent</strong></p>
                            <div className="mls-seller-name">{props.seller2name}</div>
                        </div>
                    </div>

                    <div className="mls-seller-contact">
                        <div className="mls-seller-phone"><i className="fal fa-phone-alt" /> {props.seller2phone}</div>
                        <div className="mls-seller-email"><i className="fal fa-envelope" /> {props.seller2email}</div>
                    </div>
                </div>
            }

            {props.seller3phone &&
                <div className="mls-seller mls-seller-3">
                    <div className="mls-seller-header">
                        <div className="mls-seller-image" style={{ backgroundImage: `url(https://www.commercialmls.com/Media/Agent/${props.seller3ID}.jpg)` }} />
                        <div>
                            <p><strong>3rd Agent</strong></p>
                            <div className="mls-seller-name">{props.seller3name}</div>
                        </div>
                    </div>

                    <div className="mls-seller-contact">
                        <div className="mls-seller-phone"><i className="fal fa-phone-alt" /> {props.seller3phone}</div>
                        <div className="mls-seller-email"><i className="fal fa-envelope" /> {props.seller3email}</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default RealEstateSeller;