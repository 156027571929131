import React, { useState } from 'react';
import JobsPreview from './JobsPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import JobsSideBar from './JobsSideBar';
import JobsSideBarMobile from './JobsSideBarMobile';
import JobsFeatured from './JobsFeatured';
import JobsHeader from '../../Home/Components/Jobs';
import feaImage from '../../../assets/category/followone_cat_feature_jobs.jpg';

function JobsCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <JobsHeader category section="jobs" categoryList={categoryList} />
      </div>

      <div className="development-message">
        <h4>Jobs Section Under Development</h4>
        <p>We're currently working behind the scenes to build a dedicated Jobs Section designed to help you discover new career opportunities, post job listings, and connect talent with employers. Our team is actively developing and fine-tuning this feature to make sure it’s helpful, easy to navigate, and tailored to your needs. Stay tuned - exciting updates are on the way!</p>
      </div>

      <div className="coming-soon-holder">
        <CategoryPage
          section="jobs"
          description="Advertise an Open Position or Apply for Jobs"
          ItemPreview={JobsPreview}
          SideBar={(props) => (<JobsSideBar onLoadCategories={onUpdateCategories} {...props} />)}
          SideBarMobile={(props) => (<JobsSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
          FeaturedItems={JobsFeatured}
        />
        <div className="coming-soon-cover"></div>
      </div>
    </>
  );
}

export default JobsCategory;