import React, { useState } from 'react';
import PropertyPreview from './PropertyPreview';
import CategoryPage from './category-page';
import RealEstateSideBar from './RealEstateSideBar';
import RealEstateSideBarMobile from './RealEstateSideBarMobile';
import RealEstateFeatured from './RealEstateFeatured';
import RealEstateHeader from '../../Home/Components/RealEstate';
import feaImage from '../../../assets/category/followone_cat_feature_realestate.jpg';

function RealEstateCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <RealEstateHeader
          category
          section="realestate"
          categoryList={categoryList}
        />
      </div>
      
      <CategoryPage
        section="realestate"
        description="Search for Real Estate Listings"
        ItemPreview={PropertyPreview}
        SideBar={(props) => (<RealEstateSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<RealEstateSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={RealEstateFeatured}
      />
    </>
  );
}

export default RealEstateCategory;