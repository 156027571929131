import React, { useState } from 'react';
import ServicePreview from './ServicePreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import ServicesSideBar from './ServicesSideBar';
import ServicesSideBarMobile from './ServicesSideBarMobile';
import ServicesFeatured from './ServicesFeatured';
import ServicesHeader from '../../Home/Components/Services';
import feaImage from '../../../assets/category/followone_cat_feature_services.jpg';

function ServicesCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <ServicesHeader category section="services" categoryList={categoryList} />
      </div>

      <div className="development-message">
        <h4>Services Section Under Development</h4>
        <p>We're building the Services Section — a dedicated space where users will soon be able to offer, discover, and request services directly from the community. Our team is working on the tools and features needed to make sharing and browsing services simple, safe, and seamless. Stay tuned as we bring this section to life!</p>
      </div>

      <div className="coming-soon-holder">
        <CategoryPage
          section="services"
          description="Offer Your Services or Hire Someone Else’s"
          ItemPreview={ServicePreview}
          SideBar={(props) => (<ServicesSideBar onLoadCategories={onUpdateCategories} {...props} />)}
          SideBarMobile={(props) => (<ServicesSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
            FeaturedItems={ServicesFeatured}
        />
        <div className="coming-soon-cover"></div>
      </div>
    </>
  );
}

export default ServicesCategory;