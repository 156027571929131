import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function JobsSideBar(props) {
  // return (<SideBar {...props} 
  //   section="jobs"
  //   searchTitle="Search Jobs"
  //   createNewTitle="New Job"
  //   filterList={[
  //     { name:'keywords', value: 'Enter Keywords' }, 
  //   ]}
  // />);
  return null;
}

export default JobsSideBar;