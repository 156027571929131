import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import Swiper from "react-id-swiper";

const HomeSlider = (props) => {
  const params = {
    slidesPerView: "auto",
    // spaceBetween: 30,
    freeMode: true,
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: false,
    },
  };

  if (
    ["personal", "organization", "institution", "public-figure"].includes(
      props.loggedInUser.accountType
    )
  ) {
    return (
      <div className="logged-in-header_to-do">
        <div className="logged-in-header_to-do_swiper">
          <Swiper {...params}>
            <a
              className="home-box-divider home-box-divider-social"
              href="#social"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Social</span>
            </a>
            <Link
              to="/blogs/new-blog"
              className="home-swiper-button blog-gradient"
            >
              <i className="fal fa-blog" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Write a Blog")}
              </span>
            </Link>
            <Link
              to="/photos/new-photo"
              className="home-swiper-button photos-gradient"
            >
              <i className="fal fa-camera-retro" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Photos")}
              </span>
            </Link>
            <Link
              to="/videos/new-video"
              className="home-swiper-button videos-gradient"
            >
              <i className="fal fa-video" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Videos")}
              </span>
            </Link>
            <Link
              to="/events/new-event"
              className="home-swiper-button events-gradient"
            >
              <i className="fal fa-calendar-alt" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add an Event")}
              </span>
            </Link>
            {/* <Link to="/groups/new-group" className="home-swiper-button groups-gradient"><i className="fal fa-users"></i> <span>{getTranslatedPhrase(props.userLanguage,'Start a Group')}</span></Link>    */}

            <a
              className="home-box-divider home-box-divider-business"
              href="#sell"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Sell</span>
            </a>
            {/* <Link
              to="/store/new-product"
              className="home-swiper-button store-gradient"
            >
              <i className="fal fa-shopping-bag" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Products")}
              </span>
            </Link> */}
            <Link
              to="/autos/new-auto-listing"
              className="home-swiper-button auto-gradient"
            >
              <i className="fal fa-car" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Cars")}
              </span>
            </Link>
            {/* <Link to="/food/new-food-listing" className="home-swiper-button food-gradient"><i className="fal fa-utensils" /> <span>{getTranslatedPhrase(props.userLanguage, 'Add Food')}</span></Link> */}
            <Link
              to="/realestate/new-property"
              className="home-swiper-button real-estate-gradient"
            >
              <i className="fal fa-building" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Properties")}
              </span>
            </Link>
            {/* <Link
              to="/travel/new-travel"
              className="home-swiper-button travel-gradient"
            >
              <i className="fal fa-bed" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add Travel Package")}
              </span>
            </Link> */}

            {/* <a
              className="home-box-divider home-box-divider-service"
              href="#post"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Post</span>
            </a> */}
            {/* <Link
              to="/jobs/new-job"
              className="home-swiper-button jobs-gradient"
            >
              <i className="fal fa-briefcase" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Jobs")}
              </span>
            </Link> */}
            {/* <Link
              to="/services/new-service"
              className="home-swiper-button services-gradient"
            >
              <i className="fal fa-wrench" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add Services")}
              </span>
            </Link> */}
          </Swiper>
        </div>
      </div>
    );
  }

  if (props.loggedInUser.accountType === "business") {
    return (
      <div className="logged-in-header_to-do">
        <div className="logged-in-header_to-do_swiper">
          <Swiper {...params}>
            <a
              className="home-box-divider home-box-divider-business"
              href="#sell"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Sell</span>
            </a>
            <Link
              to="/store/new-product"
              className="home-swiper-button store-gradient"
            >
              <i className="fal fa-shopping-bag" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Products")}
              </span>
            </Link>
            <Link
              to="/autos/new-auto-listing"
              className="home-swiper-button auto-gradient"
            >
              <i className="fal fa-car" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Cars")}
              </span>
            </Link>
            {/* <Link
              to="/food/new-food-listing"
              className="home-swiper-button food-gradient"
            >
              <i className="fal fa-utensils" />{" "}
              <span>{getTranslatedPhrase(props.userLanguage, "Add Food")}</span>
            </Link> */}
            <Link
              to="/realestate/new-property"
              className="home-swiper-button real-estate-gradient"
            >
              <i className="fal fa-building" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Sell Properties")}
              </span>
            </Link>
            {/* <Link
              to="/travel/new-travel"
              className="home-swiper-button travel-gradient"
            >
              <i className="fal fa-bed" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add Travel Package")}
              </span>
            </Link> */}

            <a
              className="home-box-divider home-box-divider-service"
              href="#post"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Post</span>
            </a>
            <Link
              to="/jobs/new-job"
              className="home-swiper-button jobs-gradient"
            >
              <i className="fal fa-briefcase" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Jobs")}
              </span>
            </Link>
            <Link
              to="/services/new-service"
              className="home-swiper-button services-gradient"
            >
              <i className="fal fa-wrench" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add Services")}
              </span>
            </Link>

            <a
              className="home-box-divider home-box-divider-social"
              href="#social"
              style={{ cursor: "initial" }}
            >
              <span style={{ display: "none" }}>Social</span>
            </a>
            <Link
              to="/blogs/new-blog"
              className="home-swiper-button   blog-gradient"
            >
              <i className="fal fa-blog" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Write a Blog")}
              </span>
            </Link>
            <Link
              to="/photos/new-photo"
              className="home-swiper-button photos-gradient"
            >
              <i className="fal fa-camera-retro" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Photos")}
              </span>
            </Link>
            <Link
              to="/videos/new-video"
              className="home-swiper-button videos-gradient"
            >
              <i className="fal fa-video" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Post Videos")}
              </span>
            </Link>
            <Link
              to="/events/new-event"
              className="home-swiper-button events-gradient"
            >
              <i className="fal fa-calendar-alt" />{" "}
              <span>
                {getTranslatedPhrase(props.userLanguage, "Add Event")}
              </span>
            </Link>
            {/* <Link to="/groups/new-group" className="home-swiper-button groups-gradient"><i className="fal fa-users"></i> <span>{getTranslatedPhrase(props.userLanguage,'Start a Group')}</span></Link> */}
          </Swiper>
        </div>
      </div>
    );
  }
};

const mapStateToPersonalHeaderProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    loggedInUser: state.loggedInUser,
  };
};

export default connect(mapStateToPersonalHeaderProps, null)(HomeSlider);
