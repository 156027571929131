import { Helmet } from "react-helmet";
import React from "react";
import ImageFeature1 from '../../../assets/static/healthcare_img_1.jpg';
import Healthcare from "../../Home/Components/Healthcare";

const HealthcarePage = props => {
    return (
        <>
            <div className="category-search-panel csp-healthcare">
                <Healthcare category />
            </div>

            <div className="main-100 press preview-page">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Healthcare | Followone</title>
                    <meta name="description" content="Healthcare is currently under development." />
                    <link rel="canonical" href="https://followone.com/healthcare" />
                </Helmet>

                <div className="main-100-header">
                    <h1>Followone Healthcare</h1>
                    <p>Under Development</p>
                </div> 

                <div className="static-page-feature">
                    <img src={ImageFeature1} alt="Followone Healthcare" />
                    <p style={{ textAlign: 'left' }}>Followone Healthcare is where care meets compassion. We understand the difficult journey of finding the right home for your loved ones, which is why we’re dedicated to connecting families with Adult Family Homes and assisted living facilities that provide the warmth, care, and dignity every person deserves. With Followone, you can explore available care facilities and treatment centers with ease, ensuring you find the perfect match for your loved ones. Additionally, our platform allows you to browse and shop for essential healthcare products, all in one place, giving you the peace of mind you need during life’s most challenging moments.</p>
                </div>
            </div>
        </>
    )
}

export default HealthcarePage;