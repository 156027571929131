import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import actionType from "../../../redux-store/action-type";
import BackgroundImage from "../../../assets/home/home-search-food.jpg";
import AdvFood from "../../../shared/components/Modals/Search/AdvFood";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import Axios from "axios";
import Autosuggest from "react-autosuggest";
import { hostNameAndPort } from "../../../shared/util/vars";

const FoodsSearch = (props) => {
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityMissing, setCityMissing] = useState(false);
  const [stateVar, setStateVar] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [priceAscending, setPriceAscending] = useState(0);
  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const [viewsDescending, setViewsDescending] = useState(false);
  const [withCoupons, setWithCoupons] = useState(false);
  const [rating, setRating] = useState(0);

  const loggedInUser = useSelector((state) => state.loggedInUser);
  const userLanguage = useSelector((state) => state.userLanguage);
  const searchQuery = useSelector((state) => state.searchQuery);
  const [country, setCountry] = useState(
    JSON.parse(searchQuery || "{}").countryUpdated
  );
  const windowLocationSearch = window.location.search;

  function checkLogin() {
    if (!loggedInUser)
      props.onClickLoginButtonHandler("/food/new-food-listing");
    else props.history.push("/food/new-food-listing");
  }

  let currentCountry = JSON.parse(searchQuery || "{}").countryUpdated;
  useEffect(() => {
    if (currentCountry !== country) {
      const ts = {
        category,
        subcategory,
        title,
        address,
        country,
        stateVar,
        city,
        zipCode,
        priceAscending,
        priceMin,
        priceMax,
        viewsDescending,
        withCoupons,
        rating,
      };
      for (let i of Object.keys(ts)) onChangeItem("", i);
      setCountry(currentCountry);
      if (!!props.category) props.history.push("/food");
    }
  }, [currentCountry]);

  useEffect(() => {
    if (!windowLocationSearch) {
      const ts = {
        category,
        subcategory,
        title,
        address,
        country,
        stateVar,
        city,
        zipCode,
        priceAscending,
        priceMin,
        priceMax,
        viewsDescending,
        withCoupons,
        rating,
      };
      for (let i of Object.keys(ts)) onChangeItem("", i);
      setCountry(currentCountry);
      return;
    }
    const urlParams = new URLSearchParams(windowLocationSearch);

    let cat = urlParams.get("category") || "";
    cat = cat.replace(/%26/g, "&");
    if (cat === "all") cat = "";
    let subcat = urlParams.get("subcategory") || "";
    subcat = subcat.replace(/%26/g, "&");
    if (subcat === "all") subcat = "";
    if (category !== cat) setCategory(cat);
    if (subcategory !== subcat) setSubcategory(subcat);

    const ts = {
      title,
      address,
      country,
      stateVar,
      city,
      zipCode,
      priceAscending,
      priceMin,
      priceMax,
      viewsDescending,
      withCoupons,
      rating,
    };
    let changeState = false;

    const ns = {
      title: urlParams.get("keywords") || "",
      address: urlParams.get("address") || "",
      country: urlParams.get("country") || "",
      stateVar: urlParams.get("state") || "",
      city: urlParams.get("city") || "",
      zipCode: urlParams.get("zipCode") || "",
      priceAscending: Number(urlParams.get("priceAscending")) || 0,
      priceMin: Number(urlParams.get("priceMin")) || "",
      priceMax: Number(urlParams.get("priceMax")) || "",
      viewsDescending: urlParams.get("viewsDescending") || false,
      withCoupons: urlParams.get("withCoupons") || false,
      rating: Number(urlParams.get("rating")) || 0,
    };
    if (!ns.viewsDescending || ns.viewsDescending === "false")
      ns.viewsDescending = false;
    else ns.viewsDescending = true;
    if (!ns.withCoupons || ns.withCoupons === "false") ns.withCoupons = false;
    else ns.withCoupons = true;

    let keywordsParts = ns.title.split("-");
    if (keywordsParts.length > 1) {
      keywordsParts.shift();
      keywordsParts.pop();
    }
    ns.title = keywordsParts.join("-");

    let noFilters = true;
    for (let i in ns)
      if (!!ns[i]) {
        noFilters = false;
        break;
      }

    if (noFilters) return;

    if (
      ns.title !== ts.title ||
      ns.address !== ts.address ||
      ns.country !== ts.country ||
      ns.city !== ts.city ||
      ns.stateVar !== ts.stateVar ||
      ns.zipCode !== ts.zipCode
    )
      changeState = true;

    if (changeState) {
      for (let i of Object.keys(ns)) onChangeItem(ns[i], i);
      if (!!ns.city || !!ns.stateVar)
        onChangeItem(ns.city + ", " + ns.stateVar, "address");
    }
  }, [windowLocationSearch]);

  function onChangeItem(_value, _prop, regularChange) {
    let a = {
      country: setCountry,
      stateVar: setStateVar,
      city: setCity,
      zipCode: setZipCode,
      priceAscending: setPriceAscending,
      priceMin: setPriceMin,
      priceMax: setPriceMax,
      viewsDescending: setViewsDescending,
      withCoupons: setWithCoupons,
      rating: setRating,
    };

    if (_prop === "category") {
      if (subcategory === "fast-food" && _value === "") {
        setCategory("");
        setSubcategory("");
      }
      if (category === _value && !!regularChange) setCategory("");
      else {
        setCategory(_value);
        setSubcategory("");
      }
      return;
    }
    if (_prop === "subcategory") {
      if (subcategory === _value && !!regularChange) setSubcategory("");
      else {
        setSubcategory(_value);
        setCategory("");
      }
      return;
    }
    if (_prop === "address" && _value.length < 500) {
      onChangeAddress(null, { newValue: _value });
      return;
    }
    if (_prop === "title" && _value.length < 200) {
      setTitle(_value);
      return;
    }
    a[_prop](_value);
  }

  function onChangeAddress(event, { newValue }) {
    if (/\\/g.test(newValue)) return;
    if (/^ +/.test(newValue)) newValue = newValue.replace(/^ +/, "");
    if (
      address.length < 2 &&
      newValue.length >= 1 &&
      !cityList.length &&
      !/^[0-9]+/.test(newValue)
    )
      getCityList(newValue.substring(0, 2));
    if (newValue.length < 2) {
      if (cityList.length) setCityList([]);
      setCityMissing(false);
    }
    if (/^, ?/.test(newValue)) {
      setCityMissing(true);
      newValue = newValue.replace(/^, ?/, "");
    }
    setAddress(newValue);
  }

  async function getCityList(value) {
    try {
      let localCountry;

      if (!country) {
        if (document.cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
          let data = document.cookie.match(/fo_userCountry=([^;]*)(;|$)/);
          localCountry = data[1] || "USA";
        }
      } else {
        localCountry = country;
      }

      let result = await Axios(
        hostNameAndPort +
          "/api/cities/options-for/" +
          localCountry +
          "/" +
          value
      );
      let { data } = result;
      if (!data.error) {
        setCityList(data.items);
      } else setCityList([]);
    } catch (error) {
      setCityList([]);
    }
  }

  function getSuggestions(value = "") {
    if (/\\/g.test(value)) return cityList;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let a = cityList;
    let r;
    if (value.length && value[value.length - 1] === " ") {
      let v = value.substring(0, value.length - 1);
      r = new RegExp("^" + v + ",?", "i");
    } else r = new RegExp("^" + value, "i");
    a = a.filter((v) => r.test(v));

    return inputLength === 0 ? [] : a;
  }

  function getSuggestionValue(suggestion) {
    return suggestion;
  }

  function renderSuggestion(suggestion) {
    return <div>{suggestion}</div>;
  }

  function onSuggestionsFetchRequested({ value }) {
    setSuggestions(getSuggestions(value));
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSubmitFoodsForm(e, s) {
    e.preventDefault();
    let f;
    if (!title) f = "filters-" + Date.now();
    else f = "filters-" + title + "-" + Date.now();

    // Andrei Check - we don't use price range anywhere ?
    let priceRange =
      (priceMin && priceMin !== "0") || (priceMax && priceMax !== "0");
    let a = address;
    let realAddress = a;
    if (cityMissing) {
      // Andrei Check - we don't use real address ? asa-mi zice the warning.
      realAddress = ", " + realAddress;
      a = ", " + a;
    }
    a = a.split(",");

    let city = "";
    let zipCode = "";
    let state = "";
    if (a.length > 1) {
      city = a[0].trim();
      state = a[1].trim();
    } else {
      a = a[0].trim();
      if (/^[-a-z' ]+$/.test(a.toLowerCase())) city = a;
      // else if (/^[-a-z0-9]+$/.test(a.toLowerCase())) zipCode = a;
    }
    let s_q = JSON.parse(searchQuery || "{}");
    if (!state && country === s_q.country) state = s_q.region;

    if (!s)
      props.history
        .push(`/food/search/${f}?priceAscending=0&priceMin=&priceMax=&viewsDescending=false&priceRange=&priceMin=&priceMax=&withCoupons=false&rating=&
city=${city}&zipCode=${zipCode}&state=${state}&country=${country}&address=&category=${category}&subcategory=${subcategory}&keywords=${f}`);
    else {
      // s = s.replace(/filters-([a-z(%20)0-9]*-)?[0-9]*/g, f);
      props.history.push(s);
    }
  }

  const advancedSearchHandler = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const inputProps = {
    placeholder: getTranslatedPhrase(userLanguage, "Type a city, state."),
    value: address,
    onChange: onChangeAddress,
  };

  return (
    <>
      {modalOpen && (
        <AdvFood
          closeModal={closeModal}
          {...props}
          onSubmitHandler={onSubmitFoodsForm}
        />
      )}

      <div
        className="home-search-box home-food-box"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="hsb-background" />
        <div className="hsb-container">
          <h2>{getTranslatedPhrase(userLanguage, "Foods")}</h2>
          <form onSubmit={onSubmitFoodsForm}>
            <div className="hsb-filter">
              <input
                type="radio"
                name="food"
                id="foodall"
                checked={category === "" && subcategory === ""}
                onChange={(e) => null}
              />{" "}
              <label
                htmlFor="foodall"
                onClick={(e) => onChangeItem("", "category", true)}
              >
                {getTranslatedPhrase(userLanguage, "All")}
              </label>
              <input
                type="radio"
                name="food"
                id="fastfood"
                checked={subcategory === "fast-food"}
                onChange={(e) => null}
              />{" "}
              <label
                htmlFor="fastfood"
                onClick={(e) => onChangeItem("fast-food", "subcategory", true)}
              >
                {getTranslatedPhrase(userLanguage, "Fast Food")}
              </label>
              {/* <input type="radio" name="food" id="french" checked={category === "french"} onChange={e => null} /> <label htmlFor="french" onClick={e => onChangeItem("french", "category", true)}>{getTranslatedPhrase(userLanguage, 'French')}</label> */}
              <input
                type="radio"
                name="food"
                id="italian"
                checked={category === "italian"}
                onChange={(e) => null}
              />{" "}
              <label
                className="mobile-hide"
                htmlFor="italian"
                onClick={(e) => onChangeItem("italian", "category", true)}
              >
                {getTranslatedPhrase(userLanguage, "Italian")}
              </label>
              <input
                type="radio"
                name="food"
                id="greek"
                checked={category === "greek"}
                onChange={(e) => null}
              />{" "}
              <label
                className="mobile-hide"
                htmlFor="greek"
                onClick={(e) => onChangeItem("greek", "category", true)}
              >
                {getTranslatedPhrase(userLanguage, "Greek")}
              </label>
              <input
                type="radio"
                name="food"
                id="asian"
                checked={category === "asian"}
                onChange={(e) => null}
              />{" "}
              <label
                className="mobile-hide"
                htmlFor="asian"
                onClick={(e) => onChangeItem("asian", "category", true)}
              >
                {getTranslatedPhrase(userLanguage, "Asian")}
              </label>
            </div>

            <div className="foods-inputs">
              <div className="hsb-input hsb-input-food">
                <input
                  type="text"
                  placeholder={getTranslatedPhrase(
                    userLanguage,
                    "Enter food name."
                  )}
                  value={title}
                  onChange={(e) => onChangeItem(e.target.value, "title")}
                />
              </div>
              <div className="hsb-input hsb-input-location">
                {/* <input type="text" placeholder={getTranslatedPhrase(userLanguage, 'Location: City or ZIP Code.')} value={address} onChange={e => onChangeItem(e.target.value, "address")} /> */}

                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
              </div>
            </div>

            <div className="hsb-buttons">
              <div>
                <button
                  className="button button-white"
                  type="submit"
                  onSubmit={onSubmitFoodsForm}
                >
                  {getTranslatedPhrase(userLanguage, "Search")}
                </button>
                {props.category && (
                  <button
                    className="button button-white"
                    type="button"
                    onClick={advancedSearchHandler}
                  >
                    {getTranslatedPhrase(userLanguage, "Advanced Search")}
                  </button>
                )}
              </div>
              <div>
                <Link to="/food">
                  {props.category
                    ? getTranslatedPhrase(userLanguage, "Foods Home")
                    : getTranslatedPhrase(userLanguage, "Home")}
                </Link>{" "}
                /
                <button
                  style={{
                    fontSize: "1.4rem",
                    color: "#fff",
                    marginLeft: "1rem",
                  }}
                  onClick={checkLogin}
                >
                  {getTranslatedPhrase(userLanguage, "Post Foods")}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="coming-soon">
          {getTranslatedPhrase(userLanguage, "Under Development")}
          {!props.category && <Link to="/food" className="button">{getTranslatedPhrase(userLanguage, 'Preview')}</Link>}
        </div>
      </div>
    </>
  );
};

const mapDispatchToFoodsSearchProps = (dispatch) => {
  return {
    onClickLoginButtonHandler: (returnUrl) =>
      dispatch({
        type: actionType.LOGIN_MODAL,
        accountLoginModalActive: true,
        returnUrl,
      }),
  };
};

export default connect(
  null,
  mapDispatchToFoodsSearchProps
)(withRouter(FoodsSearch));
