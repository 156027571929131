import { Helmet } from "react-helmet";
import React from "react";
import ImageFeature1 from '../../../assets/static/travel_img_1.jpg';
import Travel from "../../Home/Components/Travel";

const TravelPage = props => {
    return (
        <>
            <div className="category-search-panel">
                <Travel category />
            </div>

            <div className="main-100 press preview-page">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Travel | Followone</title>
                    <meta name="description" content="Travel is currently under development." />
                    <link rel="canonical" href="https://followone.com/travel" />
                </Helmet>

                <div className="main-100-header">
                    <h1>Followone Travel</h1>
                    <p>Under Development</p>
                </div>

                <div className="static-page-feature">
                    <img src={ImageFeature1} alt="Followone Travel" />
                    <p style={{ textAlign: 'left' }}>
                        Followone Travel is currently under development and will become your all-in-one travel companion. From booking hotels and flights to exploring car rentals, cruises, and curated vacation packages, our goal is to make travel planning effortless and enjoyable. Future features will also include a powerful short-term rental booking system, giving both travelers and property owners a seamless experience. Stay tuned—adventure awaits with Followone Travel.</p>
                </div>
            </div>
        </>
    )
}

export default TravelPage;