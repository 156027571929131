import React, { useState } from 'react';
import ProductPreview from './ProductPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import StoreSideBar from './StoreSideBar';
import StoreSideBarMobile from './StoreSideBarMobile';
import StoreFeatured from './StoreFeatured';
import StoreHeader from '../../Home/Components/Store';
import feaImage from '../../../assets/category/followone_cat_feature_store.jpg';

const StoreCategory = () => {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <StoreHeader category section="store" categoryList={categoryList} />
      </div>

      <div className="development-message">
        <h4>Store Section Temporarily Paused & Under Review</h4>
        <p>The Followone Store and Outlets are currently paused as we reorganize and improve this section. Due to the recent 2025 tariff increases and ongoing global shipping delays, we’re taking this time to revisit our business model, enhance store features, and ensure a better experience for our customers when we relaunch.</p>
      </div>

      <div className="coming-soon-holder">
        <CategoryPage
          section="store"
          description="Look for Products, or Promote Your Own"
          ItemPreview={ProductPreview}
          SideBar={(props) => (<StoreSideBar onLoadCategories={onUpdateCategories} {...props} />)}
          SideBarMobile={(props) => (<StoreSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
          FeaturedItems={StoreFeatured}
        />

        <div className="coming-soon-cover"></div>
      </div>
    </>
  )
}

export default StoreCategory;